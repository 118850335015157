import {useState} from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {If, For} from 'babel-plugin-jsx-control-statements'
import {forEach, includes, isEqual, keys, map, mapValues, some} from 'lodash-es'
import * as allReferenceTypes from '../../content/settings/referencesTypes.json'
import ReferenceList from '../components/molecules/ReferenceList'
import ReferenceCard from '../components/atoms/ReferenceCard'
import Tag from '../components/containers/Tags'
import SubpageHero from '../components/molecules/SubpageHero'
import Navbar from '../components/containers/Navbar'
import AppShell from '../components/containers/AppShell'
import useLang from '../hooks/useLang'
import FilterButton from '../components/inputs/FilterButton'
import ReferencesFilterWrapper from '../components/atoms/FilterWrapper/ReferencesFilterWrapper'


const References = ({data, pageContext: {lang: pageLang}}) => {
  const langToSlug = mapValues(data?.markdownRemark.frontmatter, 'slug')
  const {lang} = useLang(pageLang, langToSlug)
  const pageData = data.markdownRemark.frontmatter[lang]
  const references = map(data?.references.edges, ({node}) => node.frontmatter[lang])
  const referenceTypes = allReferenceTypes[lang]
  const [filter, setFilter] = useState({keyService: 'all'})
  const filterOptions = ['all']
  const referencesTags = references.map((reference) => reference.hero.keyServices.services)

  forEach(keys(referenceTypes), (type) => {
    forEach(referencesTags, (arrayOfTags) => {
      if (some(arrayOfTags, {keyService: type}) && !includes(filterOptions, type)) {
        filterOptions.push(type)
      }
    })
  })

  const handleChange = (e) => {
    const {value} = e.target
    if (value === 'all') return setFilter({keyService: 'all'})
    return setFilter({keyService: value})
  }

  return (
    <AppShell title={pageData.menuText} lang={lang} langToSlug={langToSlug} seo={pageData.seo}>
      <Navbar title={pageData.menuText} lang={lang} langToSlug={langToSlug} />
      <SubpageHero
          title={pageData.hero.title}
          fadedSideText={pageData.hero.sideTextNumber}
          sideText={pageData.hero.sideText}
      >
        <ReferencesFilterWrapper>
          <For each="tag" of={filterOptions} index="idx">
            <FilterButton
                key={idx}
                name="referenceType"
                value={tag}
                checked={isEqual(filter, {keyService: tag})}
                onChange={handleChange}
            >
              {referenceTypes[tag]}
            </FilterButton>
          </For>
        </ReferencesFilterWrapper>
      </SubpageHero>
      <main>
        <ReferenceList>
          <For each="reference" of={references}>
            <If condition={some(reference.hero.keyServices.services, filter) ||
                  isEqual(filter, {keyService: 'all'})}
            >
              <ReferenceCard
                  key={reference.hero.title}
                  filters={filter}
                  to={reference.slug}
                  image={reference.relation.relationImage?.image}
                  alt={reference.relation.relationImage?.alt}
                  title={reference.hero.title}
                  subtitle={reference.relation.shortDescription}
                  tags={reference.hero.keyServices.services}
              >
                <For each="tag" of={reference.hero.keyServices.services}>
                  <Tag
                      key={tag.keyService}
                      tagKey={tag.keyService}
                      lang={lang}
                  />
                </For>
              </ReferenceCard>
            </If>
          </For>
        </ReferenceList>
      </main>
    </AppShell>
  )
}

export const data = graphql`
  query References($id: String!, $referenceKey: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...ReferencesPageFragment
      }
    }

    references: allMarkdownRemark(filter: {frontmatter: {cs: {templateKey: {eq: $referenceKey}}}}) {
      edges {
        node {
          frontmatter {
            ...ReferencePageFragment
          }
        }
      }
    }
  }
`

References.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    lang: PropTypes.string.isRequired,
  }).isRequired,
}

export default References
