import {
  AppIcon,
  AutomationIcon,
  CrmIcon,
  DmsIcon,
  ECommerceIcon,
  IdentityIcon,
  PortalIcon,
  PowerAppIcon,
  RpaIcon,
  UxIcon,
  WebIcon,
} from './assets/icons'


export const tagsMap = {
  app: {
    icon: <AppIcon />,
    cs: {
      label: 'Aplikace',
    },
    en: {
      label: 'App',
    },
  },
  automation: {
    icon: <AutomationIcon />,
    cs: {
      label: 'Automatizace',
    },
    en: {
      label: 'Automation',
    },
  },
  d365Crm: {
    icon: <CrmIcon />,
    cs: {
      label: 'D365 CRM',
    },
    en: {
      label: 'D365 CRM',
    },
  },
  dms: {
    icon: <DmsIcon />,
    cs: {
      label: 'DMS',
    },
    en: {
      label: 'DMS',
    },
  },
  eCommerce: {
    icon: <ECommerceIcon />,
    cs: {
      label: 'E-Commerce',
    },
    en: {
      label: 'E-Commerce',
    },
  },
  identity: {
    icon: <IdentityIcon />,
    cs: {
      label: 'Identita',
    },
    en: {
      label: 'Identity',
    },
  },
  portal: {
    icon: <PortalIcon />,
    cs: {
      label: 'Portály',
    },
    en: {
      label: 'Portal',
    },
  },
  powerApp: {
    icon: <PowerAppIcon />,
    cs: {
      label: 'Power App',
    },
    en: {
      label: 'Power App',
    },
  },
  rpa: {
    icon: <RpaIcon />,
    cs: {
      label: 'RPA',
    },
    en: {
      label: 'RPA',
    },
  },
  uxUi: {
    icon: <UxIcon />,
    cs: {
      label: 'UX/UI',
    },
    en: {
      label: 'UX/UI',
    },
  },
  web: {
    icon: <WebIcon />,
    cs: {
      label: 'Web',
    },
    en: {
      label: 'Web',
    },
  },
}
