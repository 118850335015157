import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'


const StyledBackgroundTitle = styled.div`
  position: relative;
  padding-bottom: 2.5rem;
`

const SideTextFaded = styled.h2`
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  z-index: -1;
  line-height: 11.25rem;
  font-size: 15rem;
  letter-spacing: 0.2rem;
  font-weight: ${({theme}) => theme.components.typography.weight.bold};
  user-select: none;
  color: ${({theme}) => theme.colors.background};
  text-shadow: ${({theme}) => css`
    -1px -1px 0 ${theme.colors.text},
    1px -1px 0 ${theme.colors.text},
    -1px 1px 0 ${theme.colors.text},
    1px 1px 0 ${theme.colors.text}`};


  ${({vertical}) => vertical && css`
    position: absolute;
    top: 3.5rem;
    right: unset;
    bottom: unset;
    left: 0.5rem;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 7rem;
    white-space: nowrap;

    ${media.down('mobile')(css`
      position: unset;
      text-align: start;
      margin: 3rem 1.5rem;
      writing-mode: unset;
      line-height: 6rem;
      transform: unset;
      font-size: 5rem;
    `)}
  `}

  ${media.down('mobile')(css`
    display: ${({vertical}) => vertical ? 'block' : 'none'};
    display: ${({mobileFadedSideText}) => mobileFadedSideText ? 'block' : 'none'};
  `)}
`

const StyledSideText = styled.span`
  position: absolute;
  right: 9rem;
  bottom: 7.5rem;
  transform: rotate(180deg);
  z-index: 0;
  color: ${({theme}) => theme.colors.primary};
  font-size: 1rem;
  font-weight: bold;
  user-select: none;
  writing-mode: vertical-rl;

  ${media.down('mobile')(css`
    display: none;
  `)}
`

const BackgroundTitle = ({vertical, mobileFadedSideText, fadedSideText, sideText, children, ...props}) => (
  <StyledBackgroundTitle {...props}>
    <SideTextFaded
        mobileFadedSideText={mobileFadedSideText}
        vertical={vertical}
    >
      {fadedSideText}
    </SideTextFaded>
    <StyledSideText>{sideText}</StyledSideText>
    {children}
  </StyledBackgroundTitle>
)


BackgroundTitle.defaultProps = {
  mobileFadedSideText: false,
}

BackgroundTitle.propTypes = {
  vertical: PropTypes.bool,
  mobileFadedSideText: PropTypes.bool,
  fadedSideText: PropTypes.string,
  sideText: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default BackgroundTitle
