import PropTypes from 'prop-types'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import styled from 'styled-components'


const GatsbyImageWrapper = styled(GatsbyImage)`
  width: 100%;
  height: 100%;

  & img,
  & > div {
    transition: opacity 500ms ease 0s !important;
  }
`

const Image = ({image, alt, loading, decoding, ...props}) => {
  if (!image) return null

  const gatsbyImage = getImage(image)

  return (
    <GatsbyImageWrapper
        image={gatsbyImage}
        alt={alt}
        objectFit="cover"
        loading={loading}
        decoding={decoding}
        {...props}
    />
  )
}

Image.defaultProps = {
  loading: 'lazy',
  decoding: 'async',
}

Image.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
  loading: PropTypes.oneOf(['eager', 'lazy']),
  decoding: PropTypes.oneOf(['async', 'sync', 'auto']),
}

export default Image
