import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledWrapper = styled.span`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const StyledIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms ease-in;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.background};

  ${StyledWrapper}:hover &,
  ${StyledWrapper}:focus & {
    background-color: ${({theme}) => theme.colors.primary};

    svg {
      rect,
      path,
      line,
      circle {
        stroke: ${({theme}) => theme.colors.background};
      }
    }
  }
`

const StyledTitle = styled.span`
  transition:
    opacity 200ms ease-in,
    max-width 200ms ease-in;
  opacity: 0;
  padding-left: 0.5rem;
  max-width: 0;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${({theme}) => theme.colors.background};
  font-size: 0.85rem;
  font-weight: ${({theme}) => theme.components.typography.weight.light};

  ${StyledWrapper}:hover &,
  ${StyledWrapper}:focus & {
    opacity: 1;
    /* As "width: auto" is not animatable we use "max-width" and assume individual items will never be longer */
    max-width: 9rem;
  }
`

const Tag = ({icon, children, ...props}) => (
  <StyledWrapper {...props}>
    <StyledIconWrapper>
      {icon}
    </StyledIconWrapper>
    <StyledTitle>{children}</StyledTitle>
  </StyledWrapper>
)

Tag.propTypes = {
  icon: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default Tag
