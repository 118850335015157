import {Children} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'


const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  a {
    width: 25%;
  }

  a:first-child,
  a:nth-child(6n),
  a:nth-child(7n) {
    width: 50%;
  }


  ${({remainder}) => {
    if (remainder === 1) {
      return css`
        a:nth-last-child(-n+4) {
          width: 50%;
        }
      `
    }

    if (remainder === 2) {
      return css`
        a:nth-last-child(-n+2) {
          width: 50%;
        }
      `
    }
  }}

  ${media.down('mobile')(css`
    flex-direction: column;
    padding-top: 3rem;

    a:nth-child(n) {
      width: 100%;
    }
  `)}
`

const ReferenceList = ({children, ...props}) => {
  const remainder = Children.toArray(children).length % 3

  return (
    <StyledWrapper remainder={remainder} {...props}>
      {children}
    </StyledWrapper>
  )
}

ReferenceList.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ReferenceList
