import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import FilterWrapper from './index'


const StyledFilterWrapper = styled(FilterWrapper)`
  padding-left: 7rem;
  width: 70%;

  &:last-child {
    padding-bottom: 0;
  }

  > label {
    width: 33%;
  }

  ${media.down('mobile')(css`
    padding-left: 0;
    width: 100%;

    > label {
      width: 50%;
    }
  `)}
`

const ReferencesFilterWrapper = ({children, ...props}) => (
  <StyledFilterWrapper {...props}>
    {children}
  </StyledFilterWrapper>
)

ReferencesFilterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ReferencesFilterWrapper
