import PropTypes from 'prop-types'
import {tagsMap} from '../../../tags'
import VisualTag from '../../atoms/Tags'


const Tag = ({tagKey, lang}) => {
  const tag = tagsMap[tagKey]

  return (
    <VisualTag icon={tag.icon}>
      {tag[lang].label}
    </VisualTag>
  )
}

Tag.propTypes = {
  tagKey: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
}

export default Tag
