import PropTypes from 'prop-types'
import styled from 'styled-components'
import BaseLink from '../BaseLink'
import Image from '../Image'
import Typography from '../Typography'


const StyledImage = styled(Image)`
  position: absolute !important;
  transition: transform 500ms ease-out;
  z-index: -1;
  width: 100%;
  height: 100%;
`

const StyledPhotoCard = styled.div`
  position: relative;
  transition: 500ms ease-out;
  width: 100%;
  height: 27.5rem;
  overflow: hidden;
  color: ${({theme}) => theme.colors.text};

  &:hover,
  &:focus {
    background: ${({theme}) => `${theme.colors.gray}70`};
    color: ${({theme}) => theme.colors.background};

    > ${StyledImage} {
      transform: scale(1.1);
    }
  }
`

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: 100%;
`

const StyledSubtitle = styled.span`
  font-size: 0.75rem;
  font-weight: ${({theme}) => theme.components.typography.weight.light};
`

const StyledTitle = styled(Typography)`
  padding-top: 1rem;
`

const StyledIcons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: fit-content;

  > * {
    padding: 0 0.5rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`

const ReferenceCard = ({to, image, alt, title, subtitle, children, ...props}) => {
  if (!to) return children
  return (
    <BaseLink to={to} {...props}>
      <StyledPhotoCard>
        <StyledImage image={image} alt={alt} />
        <StyledContentWrapper>
          <StyledSubtitle>{subtitle}</StyledSubtitle>
          <StyledTitle variant="h3" type="h4">{title}</StyledTitle>
          <StyledIcons>{children}</StyledIcons>
        </StyledContentWrapper>
      </StyledPhotoCard>
    </BaseLink>
  )
}

ReferenceCard.propTypes = {
  to: PropTypes.string,
  image: PropTypes.object.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ReferenceCard
