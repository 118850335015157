import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media, convertMDContent} from '../../../utils'
import BackgroundTitle from '../../atoms/BackgroundTitle'
import Typography from '../../atoms/Typography'


const StyledHero = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 0 6.125rem;
  padding: 9.25rem 0 6.25rem;

  ${media.down('mobile')(css`
    display: flex;
    flex-direction: column;
    margin: 0 1.5rem;
    padding: 5rem 0 0;
  `)}
`

const StyledTitle = styled(Typography)`
  padding-bottom: 5.625rem;
  font-size: 2.5rem;
  font-weight: bold;

  p {
    line-height: 1.5;
  }

  ${media.down('mobile')(css`
    padding-bottom: 5rem;
  `)}
`

const SubpageHero = ({title, fadedSideText, sideText, children, ...props}) => {
  return (
    <BackgroundTitle
        fadedSideText={fadedSideText}
        sideText={sideText}
        {...props}
    >
      <StyledHero>
        <StyledTitle type="div" dangerouslySetInnerHTML={{__html: convertMDContent(title)}} />
        {children}
      </StyledHero>
    </BackgroundTitle>
  )
}

SubpageHero.propTypes = {
  title: PropTypes.string,
  fadedSideText: PropTypes.string,
  sideText: PropTypes.string,
  children: PropTypes.node,
}

export default SubpageHero
